import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { authenticateUser, registerNewUser } from ".";
import { getWalletBalance } from "./dashboard";
import {
  formatNumberAsCurrency,
} from "../libs/decrypt";

/** validate login page username */
export async function validateLogin(values) {
  const errors = verifyUserLogin({}, values);

  return errors;
}

export async function validateTopUp(values) {
  const errors = verifyTopUp({}, values);

  return errors;
}

export async function validateSendSMS(values) {
  const errors = sendSMS({}, values);

  return errors;
}
export async function validateSendSMSP(values) {
  const errors = sendSMSP({}, values);
  // console.log("got here");

  return errors;
}

/** validate register form */
export async function registerValidation(values) {
  const errors = verifyUserLogin({}, values);
  verifyRegisterForm(errors, values);

  /** Register User */
  // try {
  //   const regNewUser = await registerNewUser(values);
  //   const {
  //     data: { data, message },
  //   } = regNewUser;
  //   console.log(data);
  //   console.log(message);
  //   toast.success(message);
  //   setTimeout(() => {
  //     // navigate("/auth/otp");
  //     console.log("register Successful");
  //   }, 3000);
  // } catch (error) {
  //   console.error("Error registering user");
  // }
  return errors;
}

export async function getWallet({ email, bearerToken }) {
  //Fetching Wallet
  const getWallet = await getWalletBalance({ email, bearerToken });
  const { data } = getWallet;
  console.log(data);

  // var walletEncry = CryptoJS.AES.encrypt(
  //   JSON.stringify(data.data),
  //   "secret key 123"
  // ).toString();
  // setAkpaEgo(walletEncry);
}

/** validate userLogin */
function verifyUserLogin(error = {}, values) {
  if (!values.email) {
    error.email = toast.error("Email Required.");
  } else if (values.email.includes(" ")) {
    error.email = toast.error("Wrong Email");
  }
  //  else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
  //   error.email = toast.error("Invalid email address.");
  // } 
  else if (!values.password) {
    error.password = toast.error("Password Required.");
  } else if (values.password.includes(" ")) {
    error.password = toast.error("Invalid Password.");
  }

  return error;
}

function sendSMS(error = {}, values) {
  // error.senderID = toast.error("I'm here");

  if (!values.senderID) {
    error.senderID = toast.error("senderID Required.");
  } else if (values.recipientType === "typeNumbers" && !values.typedNumbers) {
    error.typedNumbers = toast.error("Recipient number can't be empty.");
  } else if (values.recipientType === "contact_group" && !values.groupId) {
    error.typedNumbers = toast.error("Choose a Contact Group.");
  } else if (
    values.recipientType === "typeNumbers" &&
    values.typedNumbers.length < 11
  ) {
    error.typedNumbers = toast.error(
      "Recipient number should be less than 11 character."
    );
  } else if (!values.smsMessage) {
    error.smsMessage = toast.error("Message can't be left empty.");
  } else if (values.flipSchedule === true && !values.scheduleDateTime) {
    error.smsMessage = toast.error("Schedule date field is empty.");
  }

  return error;
}

function sendSMSP(error = {}, values) {
  if (!values.messageType) {
    error.messageType = toast.error("Select SMS route");
  } else if (!values.sender_ID) {
    error.sender_ID = toast.error("senderID is Required.");
  } else if (!values.smsMessage) {
    error.smsMessage = toast.error("Message can't be left empty.");
  } else if (values.flipSchedule === true && !values.scheduleDateTime) {
    error.smsMessage = toast.error("Schedule date field is empty.");
  }
  return error;
}

// function verifyTopUp(error = {}, values) {
//   if (!values.amount) {
//     error.amount = toast.error("Amount Required.");
//   } else if (values.amount.includes(" ")) {
//     error.email = toast.error("Wrong Amount.");
//   } else if (values.amount < 1000) {
//     error.email = toast.error("Amount must be more than 1000.");
//   } else if (values.amount > 350000) {
//     error.email = toast.error("Amount must not be more than 350000.");
//   }

//   return error;
// }




function verifyTopUp(error = {}, values) {
  if (!values.amount) {
    error.amount = toast.error("Amount Required.");
  } else if (values.amount.includes(" ")) {
    error.amount = toast.error("Wrong Amount.");
  } else if (values.amount < 1000) {
    error.amount = toast.error(`Amount must not be less than NGN${formatNumberAsCurrency(1000)}.`);
  }
  // else if (values.amount > 350000) {
  // error.amount = toast.error(`Amount must not be more than NGN${formatNumberAsCurrency(350000)}.`);
  // }

  return error;
}

/** validate register form */
function verifyRegisterForm(error = {}, values) {
  if (!values.firstName) {
    error.firstName = toast.error("First Name Required...!");
  } else if (values.firstName.includes(" ")) {
    error.firstName = toast.error("Wrong First Name Input...!");
  } else if (!values.lastName) {
    error.lastName = toast.error("Last Name Required...!");
  } else if (values.lastName.includes(" ")) {
    error.firstName = toast.error("Wrong Last Name Input...!");
  } else if (!values.phoneNumber) {
    error.phoneNumber = toast.error("Phone Number Required...!");
  } else if (values.phoneNumber.includes(" ")) {
    error.phoneNumber = toast.error("Wrong Phone Number Input...!");
  } else if (!values.address) {
    error.address = toast.error("Address Required...!");
  } else if (values.password !== values.confirmPass) {
    error.password = toast.error("Password dosen't match...!");
  }

  return error;
}

export async function passwordVerify(errors = {}, values) {
  /* eslint-disable no-useless-escape */
  const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

  if (!values.password) {
    errors.password = toast.error("Password Required...!");
  } else if (values.password.includes(" ")) {
    errors.password = toast.error("Wrong Password...!");
  } else if (values.password.length < 4) {
    errors.password = toast.error(
      "Password must be more than 4 characters long"
    );
  } else if (!specialChars.test(values.password)) {
    errors.password = toast.error("Password must have special character");
  }

  return errors;
}

export function formatNumber(num) {
  if (typeof num === "string") {
    num = Number(num);
  }
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}
